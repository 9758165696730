
interface ListType<T=string>{
    good:Array<{ key:number;Show:boolean;name:T;content:T;[propsData:string]:any }>
    post:Array<{ key:number;Show:boolean;name:T;content:T;[propsData:string]:any }>
    night:Array<{ key:number;Show:boolean;name:T;content:T;[propsData:string]:any }>
}
// 深拷贝
export function DataCopy( List:Array<ListType> ):ListType[]{
    let newList:ListType[] = []
    List.forEach( (item,index)=>{
        newList.push({
            good:[ ...item["good"] ],
            post:[ ...item["post"] ],
            night:[ ...item["night"] ]
        })
    } )
    return newList
}

export default [ // Show 不显示
    {
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },{
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },{
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },{
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },{
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },{
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },{
        good:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        post:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ],
        night:[
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
            { key:1,Show:true,name:'',content:'' },
        ]
    },
]